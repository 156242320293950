<template>
  <Box
    class="hits-board"
    padding="0"
  >
    <div
      v-if="!loading && studentdCanBeEliminated"
      class="hits-board__warning"
    >
      <Info
        :icon="{
          name: 'alert-triangle',
          color: 'white',
        }"
        label="<h4>Atenção</h4>"
        :text="`${$t('components.hitsBoard.notAnswered')}`"
      />
    </div>

    <div class="hits-board__hits">
      <h4>Nesta prova, você acertou</h4>

      <div
        v-if="loading"
        class="hits-board__hits--skeleton"
      >
        <SkeletonLoader
          height="16px"
          width="100%"
        />

        <SkeletonLoader
          height="32px"
          width="100%"
        />
      </div>

      <div v-else>
        <div class="hits-board__legend">
          <h5 class="hits-board__legend--left">
            {{ hitsResult.hitsCount }} de {{ itemsCount }} questões
          </h5>

          <h5>{{ hitsPercentage }}%</h5>
        </div>

        <ProgressBar
          color="#4D8AF0"
          height="32px"
          :total="itemsCount"
          :value="hitsResult.hitsCount"
        />
      </div>
    </div>
  </Box>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Info from '@/components/Info'
import ProgressBar from '@/components/ProgressBar'

export default {
  name: 'ExamResultHitsboard',

  components: {
    Info,
    ProgressBar,
  },

  props: {
    // eslint-disable-next-line vue/no-unused-properties
    assessmentId: {
      type: [Number, String],
      required: true,
    },

    examId: {
      type: [Number, String],
      required: true,
    },

    applicationId: {
      type: [Number, String],
      required: true,
    },

    isPartial: Boolean,
  },

  data() {
    return {
      loading: false,
      hitsResult: {
        hitsCount: 0,
        missCount: 1,
      },
      attempts: 0,
    }
  },

  computed: {
    ...mapGetters([
      'isRankedAssessment',
      'applicationResponse',
    ]),

    studentdCanBeEliminated() {
      return this.hitsResult.invalid
    },

    itemsCount() {
      return this.hitsResult.hitsCount + this.hitsResult.missCount
    },

    hitsPercentage() {
      const percentageModifier = 100

      return Math.round(
        (this.hitsResult.hitsCount / this.itemsCount) * percentageModifier,
      )
    },
  },

  async created() {
    await this.getApplicationResponse({
      applicationId: this.applicationId,
      examId: this.examId,
    })
    await this.load()
  },

  methods: {
    ...mapActions([
      'getApplicationResponse',
      'getHitsResult',
      'getResult',
      'endExam',
    ]),

    async load() {
      this.loading = true
      try {
        if (this.isPartial) {
          await this.loadPartialResultHits()
        } else {
          await this.loadFinalResultHits()
        }
      } catch (error) {
        if (this.attempts === 0) {
          this.attempts += 1
          const { completed } = this.applicationResponse
          if (!completed) {
            await this.endExam(this.applicationResponse.id)
          }
          await this.load()
          this.$emit('finished')
        } else {
          this.showErrorMessage(error)
        }
      } finally {
        this.loading = false
        this.$emit('finished')
      }
    },

    async loadPartialResultHits() {
      const applicationResponse = await this.getApplicationResponse(this)
      const partialResultHits = await this.getResult({
        examId: applicationResponse.examId,
        applicationResponseId: applicationResponse.id,
      })
      this.hitsResult = {
        hitsCount: partialResultHits.correctAnswers,
        missCount: partialResultHits.wrongAnswers,
      }
    },

    async loadFinalResultHits() {
      this.hitsResult = await this.getHitsResult(this)
    },

    showErrorMessage(error) {
      this.$toasted.global.error({
        message: 'Ocorreu um erro ao carregar seus acertos.',
        errorCode: error.response ? error.response.status : '',
      })
    },
  },
}
</script>

<style lang="sass">

.hits-board

  &__warning
    color: #BF3434
    padding: 8px 24px
    border-bottom: 1px solid $color-ink-lightest

    .info__icon
      background: $color_danger
      box-sizing: border-box
      padding: 8px
      border-radius: 24px

    .info__label
      color: #BF3434 !important

  &__hits
    padding: 24px
    box-sizing: border-box
    width: 80%

    +mq-m
      width: 100%

    &--skeleton
      margin-top: 32px

  &__legend
    margin-top: 24px
    display: flex
    justify-content: space-between

    &--left
      color: $color-ink-light

  &__action
    width: 40%
    display: flex
    flex-direction: column

    +mq-l
      width: 50%

    +mq-m
      display: none

    &-button
      margin-top: 24px
      width: 204px

      &--skeleton
        margin-top: 24px

</style>
