<template>
  <div class="partial-result-banner">
    <div>
      <h3 class="partial-result-banner__title">
        {{ $t('components.partialBanner.title') }}
      </h3>

      <p class="partial-result-banner__description">
        {{ $t('components.partialBanner.description') }}
      </p>

      <SasInfo
        class="partial-result-banner__info"
        :icon="{
          name: 'info',
          color: 'white',
        }"
        :value="{
          text: `${$tc('components.partialBanner.description', resultDate)}`,
          type: 0,
        }"
      />
    </div>

    <img
      v-if="!isSmallScreen"
      class="partial-result-banner__illustration"
      src="~@/assets/partial-result-banner.svg"
    >
  </div>
</template>

<script>
import SasInfo from '@/components/global/SasInfo'

export default {
  name: 'ExamResultPartialBanner',
  components: {
    SasInfo,
  },

  props: {
    resultDate: {
      type: String,
      required: true,
    },
  },

  computed: {
    isSmallScreen() {
      return this.$mq === 'x_small' || this.$mq === 'small'
    },
  },
}
</script>

<style lang="sass">

.partial-result-banner
  position: relative
  box-sizing: border-box
  padding: 32px 40px
  border-radius: 12px
  overflow: hidden
  color: white
  background: linear-gradient(80deg, #4685C9, #5196D6)

  +mq-m
    padding: 24px 32px

  +mq-s
    padding: 16px 24px

  h5:nth-child(2)

    +mq-s
      margin-bottom: 56px

  &__title,
  &__description,
  &__button
    position: relative
    z-index: 1

  &__title

    +mq-s
      font-size: 20px

    +mq-xs
      font-size: 18px

  &__button
    margin-top: 32px

  &__description
    margin-top: 8px
    opacity: .8
    max-width: 55%

    +mq-m
      max-width: 70%

    +mq-s
      font-weight: 400
      max-width: 100%

  &__illustration
    position: absolute
    bottom: 0
    right: 0
    z-index: 0
    width: 304px

    +mq-l
      width: 240px

    +mq-m
      display: none

  &__info
    margin-top: 24px
    background: rgba(0,0,0,0.2)
    border-radius: 100px
    box-sizing: border-radius
    padding: 8px 16px
    max-width: max-content
    margin-right: 320px

    p
      font-size: 13px
      line-height: 1.4

    +mq-l
      margin-right: 240px

    +mq-m
      margin-right: 0

    +mq-s
      border-radius: 12px
      display: inline-flex !important

      .info-left
        display: none

</style>
