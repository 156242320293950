<template>
  <div class="partial-result">
    <Wrapper>
      <div
        v-if="isAssessmentsExamsNavigationVisible"
        class="exams-navigation-list"
      >
        <s-button
          v-for="exam in examsNavigationItems"
          :key="exam.id"
          :variation="exam.active ? 'primary' : 'secondary'"
          @click="navigateToExam(exam)"
        >
          {{ exam.name }}
        </s-button>
      </div>

      <transition-group
        appear
        name="fade"
        tag="div"
      >
        <div
          key="banner"
          class="partial-result__banner"
        >
          <ExamResultPartialBanner :result-date="resultDate" />
        </div>

        <div
          key="partial-hitsboard"
          class="partial-result__hits"
        >
          <ExamResultHitsboard
            :application-id="applicationId"
            :assessment-id="assessmentId"
            :exam-id="examId"
            :has-action="false"
            is-partial
            @finished="examResultItemsVisible = true"
          />
        </div>

        <div
          v-if="examResultItemsVisible"
          key="itemsTable"
          class="partial-result__questions"
        >
          <h4 class="partial-result__questions-header">
            Veja seu desempenho nas questões
          </h4>

          <ExamResultItemsTable
            :exam-id="examId"
            :is-high-school="isGradedByIrt"
            is-partial
          />
        </div>
      </transition-group>
    </Wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import sortBy from 'lodash/sortBy'

import assessmentTypeIds from '@/enums/assessmentTypeIds'
import ExamResultPartialBanner from '@/components/ExamResultPartialBanner'
import ExamResultHitsboard from '@/components/ExamResultHitsboard'
import ExamResultItemsTable from '@/components/ExamResultItemsTable'

export default {
  name: 'ExamResultPartial',

  components: {
    ExamResultPartialBanner,
    ExamResultHitsboard,
    ExamResultItemsTable,
  },

  props: {
    assessmentId: {
      type: [String, Number],
      required: true,
    },

    applicationId: {
      type: [String, Number],
      required: true,
    },

    examId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      examResultItemsVisible: false,
    }
  },
  computed: {
    ...mapGetters(['assessmentType', 'assessment', 'isGradedByIrt', 'application']),

    resultDate() {
      const DATE_SPLIT_INDEX = 5

      return new Date(this.assessment.resultDate)
        .toLocaleDateString('pt-br')
        .substr(0, DATE_SPLIT_INDEX)
    },
    isAssessmentsExamsNavigationVisible() {
      return this.assessment?.typeId === assessmentTypeIds.ENEM && this.application?.status === 'PROCESSING_RESULT'
    },
    examsNavigationItems() {
      const exams = this.assessment?.exams.map((exam) => ({
        ...exam,
        active: exam.id === Number(this.$route.params.examId),
      })) || []

      return sortBy(exams, ['name'])
    },
  },
  methods: {
    navigateToExam(exam) {
      const { assessmentId, applicationId } = this.$route.params

      this.$router.push({
        name: 'examResultPartial',
        params: {
          assessmentId,
          applicationId,
          examId: exam.id,
        },
      })
      this.$router.go()
    },
  },
}
</script>

<style lang="sass">
.exams-navigation-list
  display: flex
  gap: 16px
  margin-bottom: 24px

.partial-result
  position: relative
  padding: 48px 0px
  box-sizing: border-box

  +mq-l
    position: unset

  & > .wrapper

    +mq-xs
      max-width: 100% !important

  &__banner

    +mq-xs
      padding: 0 16px

  &__hits
    margin-top: 40px

    +mq-xs
      padding: 0 16px

  &__questions
    margin-top: 40px

    &-header
      margin-bottom: 24px

  .info-block__content

    p
      line-height: inherit
</style>
